* {
    font-family: 'Montserrat', 'G20 Classic', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0px;
    text-decoration: none;
}

html, body {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    overflow-x: hidden;
    user-select: none;
    background-color: #111;
    font-weight: normal;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
}

button {
    border: none;
    background-color: transparent;
}
